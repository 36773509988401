import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesIRuthra: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Ruthra - raid guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/cat_rutha.png"
            alt="Ruthra - raid guide"
          />
        </div>
        <div className="page-details">
          <h1>Ruthra - raid guide</h1>
          <h2>Struggling with the Ruthra Raid? This guide will help you!</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Rutha Mechanics" />
        <p>
          The most important aspect of this raid is Ruthra’s passive,
          Manipulating Causality. It makes the boss immune to almost all crits
          (guaranteed crit skills such as Lizelotte’s bypass this), and locks
          your team’s ultimate abilities, which makes the stun into DPS phase
          upon the boss using its main skill imperative for good overall damage.
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image5.png"
          alt="Ruthra"
        />
        <p>
          Ruthra has a pretty simple rotation that doesn’t deviate because the
          boss doesn’t care about mana and can’t be speed reduced. The skill
          order is as follows:
        </p>
        <p>
          Normal &gt; <strong>Smash</strong> &gt; Normal &gt;{' '}
          <strong>Dissolve</strong> &gt; Normal &gt; <strong>Punishment</strong>{' '}
          &gt; <strong>Main skill</strong>
        </p>
        <p>
          The normal attacks and Smash don’t do anything of note, but Dissolve
          will debuff your team’s defense and applies crit vulnerability,
          Punishment will debuff their attack, and the main skill is very
          important as this is when you need to use a skill that stuns to
          interrupt the boss for the DPS phase.
        </p>
        <h5>Dissolve (giant eye laser):</h5>
        <StaticImage
          src="../../../images/eversoul/ruthra/image32.jpg"
          alt="Ruthra"
        />
        <p>
          This will apply the first debuffs to your team, but for the most part
          you can just ignore this one because the more punishing debuff is the
          attack down that comes after.
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image1.png"
          alt="Ruthra"
        />
        <h5>Punishment (Two arm swings where his fists rocket off):</h5>
        <StaticImage
          src="../../../images/eversoul/ruthra/image16.jpg"
          alt="Ruthra"
        />
        <p>
          This attack debuff really hurts your DPS units as it is applied right
          before the DPS phase, so you’ll definitely want to bring a unit with a
          cleanse to get rid of it right away. Examples of cleanse units will be
          discussed in the recommended units section of this guide.
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image29.png"
          alt="Ruthra"
        />
        <h5>Main skill:</h5>
        <StaticImage
          src="../../../images/eversoul/ruthra/image24.jpg"
          alt="Ruthra"
        />
        <p>
          The most important mechanic in this raid revolves around Ruthra’s main
          attack. After using Punishment, Ruthra’s rune lights will turn from
          purple to blue and she will start to summon a large orb of light which
          will manifest a large sword of light that she shanks you with. Just
          after the runes turn blue, the boss becomes vulnerable to skills that
          stun. At this point, if you do not stun the boss, it will use its main
          skill which not only hurts, but will cause your team’s DPS to suffer
          by missing the DPS phase. Watch for this icon to appear on the boss’
          status bar:
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image5.png"
          alt="Ruthra"
        />
        <p>
          Using a stun during this debuff will apply a CC to the boss that stuns
          it, causes all attacks against it to be guaranteed crits, and unlocks
          your team’s ultimate abilities. It also gives the boss crit damage
          vulnerability. This is the point where you want to unload all your DPS
          skills (the DPS phase).{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image4.png"
          alt="Ruthra"
        />
        <p>
          It is important to note that although the boss does not attack any
          faster or slower based on your actions, your reaction time on the stun
          mechanic can affect how many DPS phases you get! The last stun phase
          normally gets cut off if you’re not perfectly timing your stuns -
          however, it is possible to get one last stun off at around 2 seconds
          remaining if you are efficient with all of your stuns throughout the
          match. Cutting the boss’ main attack skill animation short by stunning
          as soon as the window is up can pay off pretty big, giving you a
          chance to throw out all your skills on a debuffed boss one last time
          before the fight ends.
        </p>
        <h5>Defense debuffs:</h5>
        <p>
          Another mechanic of note is the use of defense debuffs. Ruthra takes
          extra damage while its defense is debuffed, so bringing characters
          with abilities that do this will also help your overall damage.
        </p>
        <StaticImage
          src="../../../images/eversoul/ruthra/image20.png"
          alt="Ruthra"
        />
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Useful Characters" />
        <h5>Cleanse</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="erika" enablePopover />
          <EversoulCharacter mode="icon" slug="prim" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
        </div>
        <p>
          Out of these four, the easiest to use are Prim, Talia and Erika, as
          Jiho’s cleanse is tied to her subskill and may not go off when you
          need it to.{' '}
        </p>
        <ul>
          <li>
            Prim slots perfectly into the usual undead team, has great buffs and
            a defense debuff on top of that.{' '}
          </li>
          <li>
            Talia is great in many team comps, and brings a lot of buffs along.
          </li>
          <li>
            Erika works well in most teams, also has a defense debuff, but using
            her may be slightly harder since you have to remember her heal is a
            targeted AoE. Using a team that is split between melee and ranged
            DPS might make her harder to use.
          </li>
        </ul>
        <h5>Stun</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aira" enablePopover />
          <EversoulCharacter mode="icon" slug="erusha" enablePopover />
          <EversoulCharacter mode="icon" slug="haru" enablePopover />
          <EversoulCharacter mode="icon" slug="renee" enablePopover />
          <EversoulCharacter mode="icon" slug="velanna" enablePopover />
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="adrianne" enablePopover />
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="dora" enablePopover />
          <EversoulCharacter mode="icon" slug="mica" enablePopover />
        </div>
        <p>
          There are quite a few characters with stuns, but some of them shine
          more in this raid than others.
        </p>
        <ul>
          <li>
            Aira has become a staple raid character, bringing a lot of utility.
            She also has defense down, and her main skill is a stun on demand.
            She doesn’t even have to be highly ascended which makes her useful
            for F2P players.
          </li>
          <li>
            Erusha is perfectly suited to slot into the typical undead raid
            team, bringing along stun on demand and more DoT to raise Violette’s
            damage. Also a decent F2P option, although she is squishy at low
            ascensions and may need support (keepsakes, manors) to avoid dying
            at high boss damage stacks.
          </li>
          <li>
            Haru is another raid staple, almost always seen on a team with
            Lizelotte because of her huge team-wide attack buff. Her main skill
            stuns, but she may be better used as a DPS if you can find another
            character to stun for her.
          </li>
          <li>
            Renee is seeing big numbers this raid, going from a fodder character
            to a huge DPS capable of doing almost as much damage as Lizelotte
            given the right team and high investment. She works great as a
            stunner even at low ascensions.{' '}
          </li>
          <li>
            Velanna is a good replacement for Erusha if you’re unable to keep
            your Erusha from dying, or to use with Haru’s bleeds for guaranteed
            crits, ignoring the boss mechanic.
          </li>
          <li>
            Linzy is a decent secondary DPS option, but isn’t quite as good in
            this raid as usual since human teams suffer here.
          </li>
        </ul>
        <p>
          The other characters listed here are all viable options but may not be
          as ideal in teams for reasons such as low DPS (tanks aren’t needed for
          this raid), or not fitting into team comps quite as easily.
        </p>
        <h5>Defense reduction</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aira" enablePopover />
          <EversoulCharacter mode="icon" slug="erika" enablePopover />
          <EversoulCharacter mode="icon" slug="prim" enablePopover />
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="violette" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="miriam" enablePopover />
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
          <EversoulCharacter mode="icon" slug="jacqueline" enablePopover />
        </div>
        <p>
          As before, there are some characters that fit into teams a lot easier
          than others. Aira, Erika, and Prim are all dual-use characters for
          this raid’s mechanics and so are highly recommended.
        </p>
        <ul>
          <li>
            Lizelotte really needs no explanation. Her damage is top tier for
            raids.
          </li>
          <li>
            Violette also probably doesn’t need to be explained, as she’s been a
            raid staple since the very first raid in the game!
          </li>
          <li>
            Garnet is an amazing addition to many teams, she works well on the
            undead team or as a partner for Lizelotte, bringing amazing debuffs
            to raise your team’s damage significantly.
          </li>
          <li>
            Miriam is another great option for this raid, she can even be used
            as a main DPS if she’s well invested thanks to her recent buffs and
            her fairy advantage over Ruthra.
          </li>
        </ul>
        <p>
          The last three are options as filler as well if you need, they all
          have decent personal damage, and Linzy brings stuns so she serves dual
          purpose.
        </p>
        <h5>Main DPS</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="violette" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="miriam" enablePopover />
          <EversoulCharacter mode="icon" slug="renee" enablePopover />
        </div>
        <p>
          As with most DPS, the highest invested characters are (almost always)
          your best option, but these shine above others.
        </p>
        <ul>
          <li>
            Lizelotte is basically the bossing standard, as every raid so far
            has been a single entity, which guarantees her skills to crit.
            Ruthra is no exception, and her crit guarantee even bypasses the
            boss’ no-crit passive!
          </li>
          <li>
            Violette is the OG bossing character, with her awesome ramp-up
            damage and an easy-to-build undead team that has amazing synergy
            that’s worked on pretty much every boss so far. She even works
            without investment, making her very F2P friendly.
          </li>
          <li>
            Vivienne shines in this raid with the boss being undead. She also
            buffs your team and is so fast you can spam her skills even not
            during the DPS phase to help ramp up her damage.
          </li>
          <li>
            Miriam post-buffs is a great addition to the DPS options with her
            defense debuffs, physical defense shred, and fairy type advantage.
          </li>
          <li>
            Renee is another new addition that brings great DPS if she’s
            invested in, again with fairy advantage shining against the undead
            boss.
          </li>
        </ul>
        <SectionHeader title="Team examples" />
        <StaticImage
          src="../../../images/eversoul/ruthra/image3.jpg"
          alt="Ruthra"
        />
        <br />
        <StaticImage
          src="../../../images/eversoul/ruthra/image6.jpg"
          alt="Ruthra"
        />
        <br />
        <StaticImage
          src="../../../images/eversoul/ruthra/image8.jpg"
          alt="Ruthra"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIRuthra;

export const Head: React.FC = () => (
  <Seo
    title="Ruthra - raid guide | Eversoul | Prydwen Institute"
    description="Struggling with the Ruthra Raid? This guide will help you!"
    game="eversoul"
  />
);
